import React from 'react'
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"



const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        siteTitle: title
      }
    }
    strapiGoogleSettings {
        robot_no_index
        google_tag_code_header
        google_tag_code_body
        google_search_console
        locale
    }
}
`

const Seo = ({seotags, loc, noIndex }) => {


    const {site} = useStaticQuery(query);
    const {siteDesc} = site.siteMetadata;

    const data = useStaticQuery(query);
    const {
        strapiGoogleSettings: {
            robot_no_index,
            google_search_console,
            locale
        }
    } = data


    return (
        <>
            <div>
                <Helmet htmlAttributes={{lang: `${locale}`}}
                        /* script={[{
                            type: 'text/javascript',
                            innerHTML: `${google_tag_code_header}`
                        }]}*/
                        title={`${seotags.metatitle}`}>
                    {/* Google Search Console */}
                    <meta name="google-site-verification" content={google_search_console}/>
                    {/* End Google Search Console */}

                    <meta name={'description'} content={seotags.metadescription}/>
                    {robot_no_index === true && <meta name='robots' content='noindex'/>}
                    {noIndex === true && <meta name='robots' content='noindex' />}

                    <link rel="alternate" hrefLang="x-default"
                          href={loc?.default_localization.default_url}/>

                    {loc?.localization.map((locale, idx) => {
                        return (
                            <link key={idx} rel="alternate" hrefLang={locale.iso_code.code}
                                  href={locale.url}/>
                        )
                    })}
                    <link rel="canonical" href={seotags.chanoncial_link} />
                </Helmet>

            </div>
            {/* Google Tag Manager
            <noscript dangerouslySetInnerHTML={{
                __html: `${google_tag_code_body}`,
            }}/>
            End Google Tag Manager */}
        </>
    )
}

export default Seo
